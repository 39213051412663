<template>
  <div>
    <Modal
        v-model="modal_add_relation"
        :title="'联赛id: ' + new_relation.id"
        @on-ok="ok"
    >
        <thirdSeasonSearch :sport_id="sport_id" v-model="new_relation.third" @onSourceIdChange="newRelationSourceIdChange" ></thirdSeasonSearch>
    </Modal>
    <!-- 模态框 - 编辑赛事赛季队伍等信息-->
    <Modal v-model="modal_edit_unique_tournament" draggable footer-hide >
        <div slot="header" style="color:#f60;text-align:center; ">
            <!-- <Icon type="ios-information-circle"></Icon> -->
            <span>编辑赛事</span>
        </div>
        <div class="modal-div-containter">
            <uniqueTournamentEdit :sport_id="sport_id" :unique_tournament_id="edited_unique_tournament_id" @closePage="closeEditModalUniqueTournament"></uniqueTournamentEdit>
        </div>
    </Modal>
    <Drawer
        v-model="drawer_edit"
        width="450"
        :mask-closable="false"
    >
        <seasonEdit :sport_id="sport_id" :season_id="0" @closePage='closeEditPage'></seasonEdit>
    </Drawer>  
    <Row style="margin-bottom:10px;" :gutter="16" align="middle">
        <Col span="4" style="text-align: left">
            <Input v-model="search_key" placeholder="搜索" search @on-search="onSearch" />
        </Col>
        <Col span="5" style="text-align: left">
            <DatePicker format="yyyy-MM-dd HH:mm" :options="date_options" v-model="datarange" type="daterange" placement="bottom-end" 
                placeholder="日期范围" style="width: 100%" @on-change='onDatarangeChange' editable></DatePicker>
        </Col>
        <Col span="3" style="text-align: left">
            <Checkbox v-model="is_current" @on-change='onIsCurrentChange'>当前赛季</Checkbox>
        </Col>
        <Col span="12" style="text-align: right">
            <Button type="success" @click="drawer_edit=true" >新增</Button>
        </Col>
    </Row>
    <fitTable
        :columns="columns" 
        :data="data" 
        :loading="loading" 
    >
    </fitTable>
    <Page
        :total="total_page"
        :current="current_page"
        :page-size="page_size"
        @on-change="onPageChange"
        show-total
        show-elevator
    ></Page>
  </div>
</template>
<script>

import { getSeason, addSeasonRelation, delSeasonRelation, updateSeason } from '@/api/ballsports/base/season';
import thirdSeasonSearch from './third_source_search.vue';
import seasonEdit from './edit.vue';
import moment from 'moment';
import uniqueTournamentEdit from '../unique_tournament/edit.vue';
import fitTable from '../../basic/table.vue';

const delButton = (vm, h, params) => {
    return h(
        'Poptip',
        {
        props: {
            confirm: true,
            title: '您确定要删除这条数据吗?',
            transfer: true
        },
        on: {
            'on-ok': () => {
                // vm.delete(params.row.id)
                // 删除本行
                vm.data.splice(params.index, 1)
            }
        }
        },
        [
        h(
            'Button',
            {
                style: {
                    margin: '2px 2px'
                },
                props: {
                    type: 'warning',
                    size: 'small'
                }
            },
            '删除'
        )
        ]
    )
}

const editButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'info',
            },
            on: {
                click: () => {
                    // 路由跳转到编辑页面
                    let query = { sport_id: vm.sport_id, season_id: params.row.id }
                    // let resolve_data = vm.$router.resolve({ path: '/ballsports/base/season/detail', query: query })
                    // window.open(resolve_data.href, '_blank')
                    let sport = vm.$conf.ballsports.sports[vm.sport_id];
                    if (sport){
                        let path = '/ballsports/'+ sport.name_en +'/season/detail';
                        let resolve_data = vm.$router.resolve({ path: path, query: query })
                        window.open(resolve_data.href, '_blank')
                    }else{
                        vm.$Message.error("暂不支持的运动类型")
                    }
                }
            }
        },
        '编辑'
    )
}

const mapButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
            },
            on: {
                click: () => {
                    // 路由跳转到编辑页面
                    let query = { sport_id: vm.sport_id, season_id: params.row.id }
                    // let resolve_data = vm.$router.resolve({ path: '/ballsports/base/season/map', query: query })
                    let sport = vm.$conf.ballsports.sports[vm.sport_id];
                    if (sport){
                        let path = '/ballsports/'+ sport.name_en +'/season/map';
                        let resolve_data = vm.$router.resolve({ path: path, query: query })
                        window.open(resolve_data.href, '_blank')
                    }else{
                        vm.$Message.error("暂不支持的运动类型")
                    }
                }
            }
        },
        '对阵图'
    )
}

const mapV2Button = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: 'success',
            },
            on: {
                click: () => {
                    // 路由跳转到编辑页面
                    let query = { sport_id: vm.sport_id, season_id: params.row.id }
                    // let resolve_data = vm.$router.resolve({ path: '/ballsports/base/season/map', query: query })
                    let sport = vm.$conf.ballsports.sports[vm.sport_id];
                    if (sport){
                        let path = '/cmsv2/ballsports/season/map';
                        let resolve_data = vm.$router.resolve({ path: path, query: query })
                        window.open(resolve_data.href, '_blank')
                    }else{
                        vm.$Message.error("暂不支持的运动类型")
                    }
                }
            }
        },
        '对阵图'
    )
}

const tableButton = (vm, h, params) => {
  // let self = this;
    return h(
        'Button',
        {
            style: {
                margin: '2px 2px'
            },
            props: {
                type: '',
            },
            on: {
                click: () => {
                    // 路由跳转到编辑页面
                    let query = { sport_id: vm.sport_id, season_id: params.row.id }
                    // let resolve_data = vm.$router.resolve({ path: '/ballsports/base/season/map', query: query })
                    let sport = vm.$conf.ballsports.sports[vm.sport_id];
                    if (sport){
                        let path = '/ballsports/'+ sport.name_en +'/season/table';
                        let resolve_data = vm.$router.resolve({ path: path, query: query })
                        window.open(resolve_data.href, '_blank')
                    }else{
                        vm.$Message.error("暂不支持的运动类型")
                    }
                }
            }
        },
        '积分榜'
    )
}

export default {
    name: "season-list",
    props: {
        sport_id: Number,   // 运动id
        unique_tournament_id: Number,  // 联赛id
    },
    components: {
        thirdSeasonSearch,
        seasonEdit,
        uniqueTournamentEdit,
        fitTable,
    },
    data () {
        let self = (window.x = this)
        return {
            drawer_edit: false,
            search_key: '',
            loading: true,
            total_page: 0,
            page_size: 0,
            current_page: 1,
            modal_add_relation: false,
            columns: [
                {
                    title: 'id',
                    align: 'center',
                    key: 'id',
                    width: 100,
                },
                
                {
                    title: '名称',
                    align: 'center',
                    key: 'name',
                    minWidth: 80,
                },
                {
                    title: '简称',
                    align: 'center',
                    key: 'abbr',
                    minWidth: 80,
                },
                {
                    title: '赛事',
                    align: 'left',
                    key: 'unique_tournament_id',
                    minWidth: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let unique_tournament = currentRow.unique_tournament 
                        return h(
                            'Row', unique_tournament.id>0 ? [
                                h("Col", 
                                    [
                                        h('span', {class: 'text-link', on: {
                                            'click':() => {
                                                if (! self.$unit.filterGameids( [1,2], self.sport_id ) ){
                                                    self.editUniqueTournament(unique_tournament.id);
                                                }
                                            }
                                        } },  unique_tournament.id + ' ',  ),
                                        h('span', {class: 'text-main',on: {
                                            'click':() => {
                                                if (! self.$unit.filterGameids( [1,2], self.sport_id ) ){
                                                    self.editUniqueTournament(unique_tournament.id);
                                                }
                                            }
                                        } }, unique_tournament.name,  ),
                                    ]
                                ,),
                            ] : ''
                        )
                    }
                },
                {
                    title: '年份',
                    align: 'center',
                    key: 'year',
                    width: 80,
                },
                {
                    title: '开始时间',
                    align: 'center',
                    key: 'start_time',
                    width: 100,
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h( 'span', moment.unix(currentRow.start_time).format('YYYY-MM-DD HH:mm') )
                    }
                },
                {
                    title: '结束时间',
                    align: 'center',
                    key: 'end_time',
                    width: 100,
                    render: function (h, params) {
                        let currentRow = params.row;
                        return h( 'span', moment.unix(currentRow.end_time).format('YYYY-MM-DD HH:mm') )
                    }
                },
                {
                    title: '当前赛季',
                    align: 'center',
                    key: 'is_current',
                    width: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let is_current = currentRow.is_current;
                        return h('Checkbox', {
                            props: {
                                "value": is_current === 1,
                            },
                            on: {
                                'on-change': (value) => {
                                    self.editIsCurrent(currentRow.id, currentRow.sport_id||self.sport_id, value? 1: 0)
                                    currentRow.is_current = value? 1: 0;
                                }
                            }
                        })
                    }
                },
                {
                    title: '是否中立',
                    align: 'center',
                    key: 'neutral_ground',
                    width: 80,
                    render: function (h, params) {
                        let currentRow = params.row
                        let neutral_ground = currentRow.neutral_ground;
                        return h('span', (neutral_ground===1) ? '是': '否')
                    }
                },
                // {
                //     title: '关联',
                //     align: 'left',
                //     key: 'relation',
                //     minWidth: 120,
                //     render: function (h, params) {
                //         let currentRow = params.row;
                //         let relation = currentRow.relation || {};
                //         if (relation && relation.length > 0){
                //             var _texts =  [ ]
                //             relation.forEach((element, _index) => {
                //                 let source_name = element.source_name;
                //                 _texts.push( 
                //                     h("Row", 
                //                         [h('Col', { attrs: { span: 3 }}, 
                //                             [h('Poptip', {
                //                                     props: {
                //                                         placement: 'left-start',
                //                                         confirm: true,
                //                                         transfer: true,
                //                                         title: '确定删除"'+ source_name +'"吗？',
                //                                     },
                //                                     on: {
                //                                         'on-ok': () => {
                //                                             // this.deletedMind();//调用删除方法
                //                                             self.delRelation( currentRow.id , element.source_id, element.third_id);
                //                                             relation.splice(_index, 1);
                //                                         },
                //                                         'on-cancel': () => {
                //                                         }
                //                                     }
                //                                 }, 
                //                                 [h('Icon', 
                //                                     {   
                //                                         attrs: { type: "md-trash", }, 
                //                                         // on: {
                //                                         //     'click':() => {
                //                                         //         relation.splice(_index, 1);
                //                                         //     }
                //                                         // } 
                //                                     },  
                //                                 )] 
                //                             )],
                //                         ),
                //                         h('Col', { attrs: { span: 5 }}, 
                //                             [h('span', 
                //                                 {   
                //                                     class: "text-main", 
                //                                 }, 
                //                                 element.source_name + ':',
                //                             )]
                //                         ),
                //                         h('Col', { attrs: { span: 6 }}, 
                //                             [h('span', 
                //                                 {   
                //                                     class: "text-link", 
                //                                 }, 
                //                                 element.third_id,
                //                             )] 
                //                         ),
                //                         // h('Col', { attrs: { span: 10 }}, 
                //                         //     [h('span', 
                //                         //         {   
                //                         //             class: "text-assist", 
                //                         //         }, 
                //                         //         element.third_name,
                //                         //     )] 
                //                         // ),
                //                         ]
                //                     )
                //                 )
                //             });
                //         }else{
                //             var _texts = [];
                //         }
                //         _texts.push( h("Row", 
                //                         [h('Col', { attrs: { span: 3 }}, 
                //                             [h('Icon', 
                //                                 {   
                //                                     attrs: { type: "md-add-circle", }, 
                //                                     on: {
                //                                         'click':() => {
                //                                             // 为当前联赛新增关联
                //                                             self.add(currentRow.id);
                //                                             self.edited_relation = relation;
                //                                         }
                //                                     } 
                //                                 },  
                //                             )] 
                //                         ),
                //                         ]
                //                     ) )
                //         return _texts
                //     }
                // },
                {
                    title: '操作',
                    key: 'handlers',
                    align: 'right',
                    width: 150,
                    render (h, params) {
                        if (self.$unit.filterGameids( [1,2], self.sport_id ) ){
                            return [ mapV2Button(self, h, params), 
                                 tableButton(self, h, params) ] // delButton(self, h, params),
                        }else if (3<=self.sport_id <= 100){
                            return [ editButton(self, h, params), mapV2Button(self, h, params), 
                                 tableButton(self, h, params) ] // delButton(self, h, params),
                        }else{
                            return [ mapButton(self, h, params), 
                                 tableButton(self, h, params) ] // delButton(self, h, params),
                        }
                    }
                }
            ],
            data: [],
            new_relation: {
                id: 0, // 比赛id
                source_id: '',  // 源id
                third: {
                    id: 0,      // 赛事id
                    name: '',   // 赛事名称
                }
            },
            edited_relation: [],
            start_date: 0,
            end_date  : 0,
            datarange : [],
            date_options: {
                shortcuts: [
                    {
                        text: '1个月',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            return [start, end];
                        }
                    },
                    {
                        text: '1周',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            return [start, end];
                        }
                    },
                    {
                        text: '6小时',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 6);
                            return [start, end];
                        }
                    },
                    {
                        text: '3小时',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 3);
                            return [start, end];
                        }
                    },
                    {
                        text: '30分钟',
                        value () {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 1800 * 1000);
                            return [start, end];
                        }
                    },
                    {
                        text: '昨天',
                        value () {
                            const end = new Date(new Date().toLocaleDateString());
                            const start = new Date();
                            start.setTime(end.getTime() - 3600 * 1000 * 24);
                            return [start, end];
                        }
                    },
                    {
                        text: '今天',
                        value () {
                            const end = new Date();
                            const start = new Date(new Date().toLocaleDateString())
                            end.setTime(start.getTime() + 3600 * 1000 * 24);
                            return [start, end];
                        }
                    },
                    {
                        text: '明天',
                        value () {
                            const end = new Date();
                            const start = new Date(new Date().toLocaleDateString())
                            start.setTime(start.getTime() + 3600 * 1000 * 24);
                            end.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
                            return [start, end];
                        }
                    },
                    
                ]
            },
            is_current: false,
            modal_edit_unique_tournament: false,
            edited_unique_tournament_id: 0,
        }
    },
    methods: {
        onPageChange (page) {
            this.current_page = page
            this.getData()
        },
        onSearch (search_key) {
            this.current_page = 1;
            this.search_key = search_key;
            this.getData();
        },
        onCategoryIdChange(value){
            let self = this;
            self.current_page = 1;
            self.getData();
        },
        onDatarangeChange(value){
            let self = this;
            self.current_page = 1;
            if (value.length > 0){
                self.start_date = parseInt( Date.parse(value[0]) * 0.001 );
                self.end_date   = parseInt( Date.parse(value[1]) * 0.001 );
                // console.log( typeof( self.start_date ), self.start_date );
            }else{
                self.start_date = 0;
                self.end_date = 0;
            }
            self.start_date = self.start_date || 0;
            self.end_date   = self.end_date || 0;
            self.getData();
        },
        onIsCurrentChange(){
            let self = this;
            self.current_page = 1;
            self.getData();
        },
        // end
        getData () {
            let self = this;
            this.loading = true;
            // 搜索日期
            if (self.end_date > 0 && self.end_date === self.start_date){
                self.end_date = self.end_date + 3600 * 24 - 1;
            } 
            // 搜索日期
            var params = {
                sport_id: self.sport_id,
                search_key: self.search_key,
                page: self.current_page,
                start_time: self.start_date,
                end_time: self.end_date,
                is_current: self.is_current ? 1: 0,
                unique_tournament_id: self.unique_tournament_id,
            }
            getSeason(params).then(response => {
                // console.log(response)
                if (response.data.code == 0) {
                    this.data = response.data.data.list;
                    this.total_page = response.data.data.total_page;
                    this.page_size = response.data.data.page_size;
                    this.current_page = response.data.data.current_page;
                    this.loading = false;
                } else {
                    this.$Message.error(response.data.msg);
                }
            })
        },
        // end
        addRelation(_id, source_id, third_id){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                id: _id,
                source_id: source_id,
                third_id: third_id
            }
            addSeasonRelation(params).then(response => {
                if (response.data.code == 0) {
                    self.$Message.success('添加成功');
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end
        delRelation(_id, source_id, third_id){
            let self = this;
            let params = {
                sport_id: self.sport_id,
                id: _id,
                source_id: source_id,
                third_id: third_id
            }
            delSeasonRelation(params).then(response => {
                if (response.data.code == 0) {
                    // self.$Message.success(response.data.msg);
                } else {
                    self.$Message.error(response.data.msg);
                }
            })
        },
        // end 
        add ( _id ) {
            this.new_relation["id"] = _id;
            this.modal_add_relation = true;
        },
        // end
        newRelationSourceIdChange(source_id, source_name){
            this.new_relation.source_id = source_id;
            this.new_relation.source_name = source_name;
        },

        editIsCurrent(data_id, sport_id, is_current){
            let self = this;
            if (data_id > 0 && sport_id > 0){
                self.editSeason({
                    id: data_id,
                    sport_id,
                    is_current
                });
            }
        },

        editSeason(params) {
            // let self = this;
            // console.log(self.data)
            // let params = {
                // id: self.data.id,
                // sport_id: self.data.sport_id || self.sport_id,
                // unique_tournament_id: self.data.unique_tournament.id,
                // name: self.data.name,
                // abbr: self.data.abbr,
                // year: self.data.year,
                // start_time: self.data.start_time,
                // end_time: self.data.end_time,
                // neutral_ground: self.data.neutral_ground,
                // is_current: self.data.is_current,
                // deleted: self.data.deleted,
            // };
            updateSeason({params}).then(response => {
                if (response.data.code == 0) {
                    this.$Message.success("保存成功")
                } else {
                    this.$Message.error(response.data.msg)
                }
            })
        },

        ok () {
            // 为联赛新增加第三方的关联
            let self = this;
            if (self.new_relation && self.new_relation.third){
                self.addRelation(self.new_relation.id, self.new_relation.source_id, self.new_relation.third.id)
                self.edited_relation.push(
                    {
                        source_id: self.new_relation.source_id,
                        source_name: self.new_relation.source_name,
                        third_id: self.new_relation.third.id,
                        third_name: self.new_relation.third.name,
                    }
                )
            }
        },
        // delete (_id) {
        //     var params = {
        //         id: _id,
        //         type: this.data_type
        //     }
        //     deleteData(params).then(response => {
        //         if (response.data.code == 0) {
        //             return true
        //         } else {
        //             this.$Message.error(response.data.msg)
        //             return false
        //         }
        //     })
        // },
        // end 
        closeEditPage(){
            let self = this;
            self.drawer_edit = false;
        },
        // end 
        closeEditModalUniqueTournament(){
            let self = this;
            self.modal_edit_unique_tournament = false;
            self.edited_unique_tournament_id = 0;
        },
        // end
        editUniqueTournament(_id){
            let self = this;
            self.edited_unique_tournament_id = _id;
            self.modal_edit_unique_tournament = true;
        },
        // end
    },
    mounted () {
        if (this.unique_tournament_id > 0){
            // 删除联赛id
            this.columns.splice(9, 1); // 删除"关联"这一列
        }
        this.getData();
    },
    watch: {
    },
    computed: {}
}
</script>
<style scoped>
    /* @import "list.css" */
</style>